var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"id":"invoice-list"}},[(_vm.title)?_c('v-card-title',[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.showSearch)?_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('div',{staticClass:"d-flex align-center pb-5"},[_c('v-text-field',{staticClass:"mr-4",attrs:{"single-line":"","dense":"","outlined":"","hide-details":"","placeholder":"Search Fields"},model:{value:(_vm.search.all),callback:function ($$v) {_vm.$set(_vm.search, "all", $$v)},expression:"search.all"}}),_c('OrderLookup',{staticClass:"mr-4",attrs:{"outlined":true,"inverted":false,"hide-details":true,"dense":true},model:{value:(_vm.filter.OrderId),callback:function ($$v) {_vm.$set(_vm.filter, "OrderId", $$v)},expression:"filter.OrderId"}})],1)]):_vm._e(),_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.tableColumns,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.Reference",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"font-weight-medium text-decoration-none",attrs:{"to":{ name: 'invoice-view', params: { id: item.Id } }}},[_vm._v(" "+_vm._s(item.Reference)+" ")])]}},{key:"item.OrderId_StatusId_Name",fn:function(ref){
var item = ref.item;
return [(item.OrderId_StatusId_Name)?_c('order-status-chip',{attrs:{"status":item.OrderId_StatusId_Name}}):_vm._e()]}},{key:"item.Status",fn:function(ref){
var item = ref.item;
return [(item.Status)?_c('invoice-status-chip',{attrs:{"status":item.Status}}):_vm._e()]}},{key:"item.OrderId_Reference",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"font-weight-medium text-decoration-none",attrs:{"to":{ name: 'order-view', params: { id: item.OrderId } }}},[_vm._v(" "+_vm._s(item.OrderId_Reference)+" ")])]}},{key:"item.OrderId_MemberId_Reference",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"font-weight-medium text-decoration-none",attrs:{"to":{ name: 'member-view', params: { id: item.OrderId_MemberId } }}},[_vm._v(" "+_vm._s(item.OrderId_MemberId_Reference)+" ")])]}},{key:"item.ProductId_Name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ProductId_Code)+" - "+_vm._s(item.ProductId_Name)+" ")]}},{key:"item.Amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.Display.Money(item.Amount))+" ")]}},{key:"item.Discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.Display.Money(item.Discount))+" ")]}},{key:"item.Credit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.Display.Money(item.Credit))+" ")]}},{key:"item.PaidDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.Display.DisplayDate(item.PaidDate))+" ")]}},{key:"item.PaidAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.Display.Money(item.PaidAmount))+" ")]}},{key:"item.RefundedDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.Display.DisplayDate(item.RefundedDate))+" ")]}},{key:"item.RefundedAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.Display.Money(item.RefundedAmount))+" ")]}},{key:"item.Created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.Display.DateTime(item.Created))+" ")]}},{key:"item.ReplacedId",fn:function(ref){
var item = ref.item;
return [(item.ReplacedId)?_c('router-link',{staticClass:"font-weight-medium text-decoration-none",attrs:{"to":{ name: 'invoice-view', params: { id: item.ReplacedId } }}},[_vm._v(" "+_vm._s(item.ReplacedId_Reference)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return undefined}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }