/* Invoice List, Vue Component */
<template>
  <v-card id="invoice-list">
    <v-card-title v-if="title">{{title}}</v-card-title>

    <v-card-text v-if="showSearch" class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">

        <v-text-field
          v-model="search.all"
          single-line
          dense
          outlined
          hide-details
          placeholder="Search Fields"
          class="mr-4"
        ></v-text-field>

        <OrderLookup class="mr-4" v-model="filter.OrderId" :outlined="true" :inverted="false" :hide-details="true" :dense="true" />


      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedItems"
      :headers="tableColumns"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="footerProps"
      class="text-no-wrap"
    >

    <template #[`item.Reference`]="{item}">
          <router-link class="font-weight-medium text-decoration-none"
        :to="{ name: 'invoice-view', params: { id: item.Id } }">
      {{ item.Reference }}
    </router-link>
    </template>

    <template #[`item.OrderId_StatusId_Name`]="{item}">
      <order-status-chip v-if="item.OrderId_StatusId_Name" :status="item.OrderId_StatusId_Name"></order-status-chip>
    </template>

    <template #[`item.Status`]="{item}">
      <invoice-status-chip v-if="item.Status" :status="item.Status"></invoice-status-chip>
    </template>

    <template #[`item.OrderId_Reference`]="{item}">
      <router-link class="font-weight-medium text-decoration-none"
        :to="{ name: 'order-view', params: { id: item.OrderId } }">
      {{ item.OrderId_Reference }}
    </router-link>
    </template>

    <template #[`item.OrderId_MemberId_Reference`]="{item}">
      <router-link class="font-weight-medium text-decoration-none"
        :to="{ name: 'member-view', params: { id: item.OrderId_MemberId } }">
      {{ item.OrderId_MemberId_Reference }}
    </router-link>
    </template>

		<template #[`item.ProductId_Name`]="{item}">
      {{ item.ProductId_Code }} - {{ item.ProductId_Name }}
    </template>

		<template #[`item.Amount`]="{item}">
      {{ Display.Money(item.Amount) }}
    </template>

		<template #[`item.Discount`]="{item}">
      {{ Display.Money(item.Discount) }}
    </template>

    <template #[`item.Credit`]="{item}">
      {{ Display.Money(item.Credit) }}
    </template>

		<template #[`item.PaidDate`]="{item}">
      {{ Display.DisplayDate(item.PaidDate) }}
    </template>

		<template #[`item.PaidAmount`]="{item}">
      {{ Display.Money(item.PaidAmount) }}
    </template>

    <template #[`item.RefundedDate`]="{item}">
      {{ Display.DisplayDate(item.RefundedDate) }}
    </template>

    <template #[`item.RefundedAmount`]="{item}">
      {{ Display.Money(item.RefundedAmount) }}
    </template>


    <template #[`item.Created`]="{item}">
      {{ Display.DateTime(item.Created) }}
    </template>

    <template #[`item.ReplacedId`]="{item}">
      <router-link v-if="item.ReplacedId" class="font-weight-medium text-decoration-none"
          :to="{ name: 'invoice-view', params: { id: item.ReplacedId } }">
        {{ item.ReplacedId_Reference }}
      </router-link>
    </template>

      <!-- actions -->
      <template #[`item.actions`]="{item}">

      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import store from '@/store'

import {
  mdiPlus,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiPencilOutline,
} from '@mdi/js'

import { ref, watch, computed, toRefs } from '@vue/composition-api'
import * as Validators from '@/datahelpers/validation'
import * as Display from '@/datahelpers/displayTypes'
import OrderLookup from '@/views/order/OrderSelect'
import OrderStatusChip from '@/views/orderstatus/OrderStatusChip'
import InvoiceStatusChip from '@/views/invoice/InvoiceStatusChip'

export default {
  components: {
    OrderLookup,
    OrderStatusChip,
    InvoiceStatusChip
  },
  props: {
    OrderId: {
      type: Number,
      required: false
    },
    title: {
      required: false,
      default: "Invoices"
    },
    showSearch: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {

  const { OrderId, title } = toRefs(props);

  // define cols
  var tableColumns = [
    {
      text: '',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
    { text: 'Created', value: 'Created' },
    { text: 'Reference', value: 'Reference' },
  ]

  // middle section of table should be included if we don;t have an order id
  if (!OrderId.value) {
    tableColumns = tableColumns.concat([
      { text: 'Order', value: 'OrderId_Reference' },
		  { text: 'Order Status', value: 'OrderId_StatusId_Name' },
      { text: 'Member', value: 'OrderId_MemberId_Reference' },
		  { text: 'Product', value: 'ProductId_Name' },
    ]);
  }
  tableColumns = tableColumns.concat([
    { text: 'Status', value: 'Status' },
		{ text: 'Replaced By', value: 'ReplacedId' },
		{ text: 'Amount', value: 'Amount' },
		{ text: 'Credit', value: 'Credit' },
		{ text: 'Description', value: 'Description' },
		{ text: 'Discount Code', value: 'DiscountCode' },
		{ text: 'Discount', value: 'Discount' },
		{ text: 'Paid Date', value: 'PaidDate' },
		{ text: 'Paid Amount', value: 'PaidAmount' },
    { text: 'Refunded Date', value: 'RefundedDate' },
		{ text: 'Refunded Amount', value: 'RefundedAmount' },
  ]);

  // threee params for fetching
  const search = ref({});
  const filter = ref({
    OrderId: (OrderId ? OrderId.value : null)
  });
  const options = ref({
    sortBy: ['Created'],
    sortDesc: [true],
    itemsPerPage: 10
  });
  const footerProps = {
    'items-per-page-options': [10, 20, 50, 100, -1]
  };

  // items
  const items = computed(() => store.state.app.Invoices || []);
  const totalItems = computed(() => store.state.app.InvoicesTotal || 0);
  const selectedItems = ref([]);
  const loading = ref(false);

  // data for filter fields
  const filterItems = ref({

  });

  // method for data loading via vuex
  const fetchData = () => {
    store
      .dispatch('app/fetchInvoices', {
        search: search.value,
        options: options.value,
        filter: filter.value,
      })
       .then(response => {
         loading.value = false
       })
      .catch(error => {
        console.log(error)
      })
  }

  watch([search, filter, options], () => {

    // start loading
    loading.value = true
    fetchData()
  }, { deep: true });



   return {
      tableColumns,
      search,
      filterItems,
      filter,
      options,
      totalItems,
      items,
      loading,
      selectedItems,
      footerProps,

      Validators,
      Display,
      icons: {
        mdiPlus,
        //mdiDeleteOutline,
        //mdiEyeOutline,
        //mdiPencilOutline,
      },
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
